
import DarkButton from '@/components/shared/dark-button'
import PrimaryTitle from '@/components/shared/primary-title'
import {imageMixin} from '@/mixins/imageMixin'
export default {
  name: 'ImageUploader',
  components: {PrimaryTitle, DarkButton},
  mixins: [imageMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    currentImageUrl: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      noImgUrl: '/UnavailableSmall.png',
      addedImageUrl: '',
      files: [],
      dragging: false,
    }
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split('.').pop() : ''
    },
  },
  methods: {
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!this.multiple && files.length > 1) {
        this.$toast.error('Only one file can be selected.')
      } else {
        files.forEach((element) => {
          if (
            element &&
            !['.jpg', '.jpeg', '.webp', '.png'].some((str) =>
              str.includes(
                element.name
                  .toLowerCase()
                  .substr(element.name.lastIndexOf('.') + 1)
              )
            )
          ) {
            this.$toast.error(
              ' Please select a valid image type (".jpg", ".jpeg", ".png", ".webp").'
            )
            return
          }
          this.files.push(element)

          // eslint-disable-next-line vue/no-mutating-props
          this.currentImageUrl = URL.createObjectURL(element)
          this.addedImageUrl = URL.createObjectURL(element)
          this.$emit('imageChanged', element)
        })
      }

      if (!files.length) {
        this.dragging = false
      }
    },
    resetUploader() {
      // eslint-disable-next-line vue/no-mutating-props
      this.currentImageUrl = ''
      this.addedImageUrl = ''
      this.files = []
      this.dragging = false
      this.$emit('imageChanged', null)
    },
  },
}
