
export default {
  name: 'DarkButton',
  props: {
    text: {
      required: true,
      type: String,
    },
  },
}
