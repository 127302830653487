export const imageMixin = {
  computed: {},
  methods: {
    getImageURL(imageCode, large = false) {
      if (!imageCode) {
        return large ? '/UnavailableLarge.png' : '/UnavailableSmall.png'
      }
      return this.$config.axios.browserBaseURL + 'images/' + imageCode
    },
  },
}
