
import LeftMenu from '@/components/common/left-menu'
import SwitchUser from '@/components/common/switch-user'
import {imageMixin} from '@/mixins/imageMixin'
import {mapGetters, mapState, mapActions} from 'vuex'
import {adminCodes} from '@/enums/adminSettings'
export default {
  name: 'NavigationBars',
  components: {LeftMenu, SwitchUser},
  mixins: [imageMixin],
  data() {
    return {
      clipped: true,
      fixed: true,
      miniVariant: true,
      right: true,
      switchUserDialog: false,
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
    ...mapState('companyDetails', ['companyDetails']),
    ...mapState('userProfile', ['userDetails']),
    ...mapGetters('settings', ['adminSettingByCode']),

    loggedIn() {
      return this.$auth.loggedIn
    },
    customerName() {
      return this.$auth.user != null ? this.$auth.user.fullname : ''
    },
    showSwitchUser() {
      return this.$auth.user.userTypeCode === 'SUPER'
    },
    showAllCustomers() {
      return true;
    },
  },
  methods: {
    ...mapActions('rememberedUser',['removeSavedUser']),
    openSignInDialog() {
      this.$router.push('/login')
    },
    profile() {
      this.$router.push('/backoffice/my-profile')
    },
    signOut() {
      this.removeSavedUser()
      this.$router.push('/logout')
    },
    updateMiniVariant(value) {
      this.miniVariant = value
    },
    showSwitchUserDialog() {
      this.switchUserDialog = true
    },
    closeSwitchUserDialog() {
      this.switchUserDialog = false
    },
  },
}
